/* You can add global styles to this file, and also import other style files */
@layer tailwind-base, primeng, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}



@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "assets/scss/variables";
@import "assets/scss/forms";
@import "assets/scss/typography";
@import "assets/scss/pager";
@import "assets/scss/global";
:root {
  --font-family: Nunito, sans-serif;
  font-family: Nunito, sans-serif;
}
.home .container {max-width: 1920px!important;}