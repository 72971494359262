input {
  &[type="checkbox"] {
    appearance: none;
    height: 24px;
    width: 24px;
    margin-right: 8px;
    border-radius: 2px;
    &:checked {
      background-color: var(--primary);
      background-image: url("../../assets/img/checkmark-white.svg");
      background-size: 17px 17px;
    }
  }
}
