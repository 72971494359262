body {
  .ngx-pagination {
    li {
      color: var(--primary);
      padding: 0;
      margin: 0 8px;
      min-width: 48px;
      height: 48px;
      text-align: center;
      display: inline-block;
      font-size: 1rem;
      line-height: 48px;

      a {
        display: block;
        padding: 0;
        color: var(--primary);
      }

      &.current {
        padding: 0;
        background: var(--primary);
        color: var(--secondary);
        border-radius: 2px;
      }

      &.pagination-previous a, &.pagination-next a {
        padding: 0 16px;
      }
    }
  }

}
