@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

/* Standaard typografie alleen toepassen op elementen zonder klassen */
h1:not([class]) {
  font-size: 4.5rem;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 3.5rem;
  }
}

h2:not([class]),
h2.ng-star-inserted {
  font-size: 1.875rem;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
}

h3:not([class]) {
  font-size: 1.125rem;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
}

h4:not([class]) {
  font-size: 1.125rem;
  font-weight: 600;
}

h5:not([class]) {
  font-size: 1rem;
  font-weight: 600;
}

h6:not([class]) {
  font-size: 1rem;
  font-weight: 400;
}

strong:not([class]),
em {
  font-weight: 700;
}