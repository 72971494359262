html, body {
  max-width: 100%;
  overflow-x: hidden !important;
}

.intro {
  a {
    color: var(--tertiary-dark);
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
    &:focus {
      outline: var(--primary) solid;
    }
  }
}

hr {
  width: 100%;
  height: 2px;
  background-color: var(--primary);
  border: none;
  margin: 1rem 0;
}

.a2a_svg {
  display: none !important;
}

.text-collapse {
  a {
    color: var(--tertiary-dark);
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
    &:focus {
      outline: solid 2px var(--tertiary-dark);
    }
  }
}
